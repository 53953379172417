/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { IoIosAddCircleOutline, IoIosArrowDown, IoIosArrowUp, IoIosClose } from 'react-icons/io'
import { Content, Collapse, Grid, Row, Col, Divider, Fade } from '@monorepo-amais/commons/components/Blocks'
import { SubTitle, HighLight, SubTitleCard } from '@monorepo-amais/commons/components/Typography'
import { LabelButton, Icon } from '@monorepo-amais/commons/components/Buttons'
import { Search } from '@monorepo-amais/commons/components/Inputs'

const COLUMNS = 2

const Card = styled.div`
	height: 81px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 0 20px 0;
`

const Name = styled.div`
	span {
		font-size: 16px;
		// font-size: calc(0.4em + 1vw);
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		max-height: 2.62em; // duas linhas
		letter-spacing: normal;
		text-align: left;
		color: ${props => props.theme.colors.black_fonts};
	}

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`

const Container = styled.div`
	border-radius: 5px;
	border: solid 1px #e2e2e2;
	border-bottom: transparent;
	background-color: #fcfcfc;
	padding: 8px 16px;
	height: 100%;
`

const ButtonInformations = styled.button`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 30px;
	background-color: ${props => props.theme.colors.primary};

	border: none;
	border-radius: 0 0 5px 5px;

	font-size: 12px;
	// font-size: calc(0.06em + 1vw);
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: right;
	color: ${theme('colors.white')};
	cursor: pointer;
`
const IoIosAddCircleOutlineIcon = styled(IoIosAddCircleOutline)`
	margin-right: 2%;
`

const AvaliableItem = styled.div`
	margin: 2%;
	text-transform: uppercase;
	font-size: 14px;
	// font-size: calc(0.3em + 1vw);
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
`
const DivForArrow = styled.div`
	// width: 200px;
	padding-left: calc(200px * 0.75);
	display: ${({ active }) => (active ? 'flex' : 'none')};
	color: ${props => props.theme.colors.primary};
	position: absolute;
	margin-top: 81px;
	height: 22px;
	align-items: flex-end;
	opacity: ${({ active }) => (active ? '1' : '0')};
	transition: height 1s;
	@media (max-width: 768px) {
		width: 0;
		padding-left: calc(172px * 0.75);
	}
`

const ArrowDown = styled.div`
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid ${props => props.theme.colors.primary};
`

const CloseIcon = styled(IoIosClose)`
	color: ${props => props.theme.colors.primary};
	width: 35px;
	height: 35px;
`

const CustomFade = styled(Fade)`
	max-width: 1360px;
	margin: auto;
`

const InformacoesHTML = styled.div`
	div,
	table {
		width: 100%;
	}
`

/**
 * Componente de cartao com dois collapses
 * @param {string} id
 * @param {string} name -
 * @param {boolean} active - cartao aberto ou nao
 * @param {function} onOpenCollapse - handler para click no cartao
 * @param {object} metadata - metadados do convenio
 * @param {array} agreements - lista de convenios
 * @param {string} widthContainer - largura atual do container para calculo
 * @param {fucntion} onSearch - handler para acao de pesquisar
 * @param {function} onChange - handler para mudancao do valor do campo de pesquisa
 * @param {array} plans - lista de planos do convenio
 */
export default ({
	name,
	onOpenCollapse,
	active,
	informacoes,
	agreements,
	widthContainer,
	onSearch,
	onChange,
	plans,
	id,
	cosmicjsInformacoesEstaticas
}) => {
	let refCollapse = useRef(null)
	const secondRef = useRef(null)
	const [secondCollapseOpened, setSecondCollapseOpen] = useState(false)
	const [style, setStyle] = useState({})

	useEffect(() => {}, [refCollapse])

	useEffect(() => {
		if (secondCollapseOpened) {
			setSecondCollapseOpen(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agreements])

	useEffect(() => {
		setStyle({ height: refCollapse ? refCollapse.clientHeight : '' })
		// Se o collapse nao estiver visisel, scrollIntoView
		const { current } = secondRef
		if (current && typeof window !== 'undefined') {
			if (current.getBoundingClientRect().top + 20 >= window.innerHeight) {
				window.scrollTo({
					behavior: 'smooth',
					top: current.getBoundingClientRect().top - 50
				})
			}
		}

		// Se o segundo collpase nao estiver visivel, scrollIntoView
		if (refCollapse && active && typeof window !== 'undefined') {
			if (refCollapse.getBoundingClientRect().top + 20 >= window.innerHeight) {
				window.scrollTo({
					behavior: 'smooth',
					top: refCollapse.getBoundingClientRect().top - 50
				})
			}
		}
	}, [agreements, secondCollapseOpened, plans])

	const handleOpenSecondCollapse = () => {
		setSecondCollapseOpen(!secondCollapseOpened)
	}

	return (
		<div>
			<Card id={`card-insurance-${name}`} active={active} secondCollapseOpened={secondCollapseOpened}>
				<Container onClick={onOpenCollapse}>
					<Name>
						<span>{name}</span>
					</Name>
				</Container>
				<ButtonInformations id={`informations-${name}`} onClick={onOpenCollapse}>
					<IoIosAddCircleOutlineIcon />
					<span>Informações</span>
				</ButtonInformations>
				<DivForArrow active={active}>
					<ArrowDown />
				</DivForArrow>
			</Card>
			<Collapse
				setRef={element => {
					refCollapse = element
				}}
				show={active}
				widthContainer={widthContainer}
			>
				<CustomFade show={active}>
					<Row justify='space-between'>
						<SubTitle>Planos Atendidos</SubTitle>
						<Icon id='close-informations' hideOnMobile={true} onClick={onOpenCollapse}>
							<CloseIcon />
						</Icon>
					</Row>
					<Row>
						<Col size='12' p='0 15px'>
							<SubTitleCard>Pesquisar Plano</SubTitleCard>
						</Col>
					</Row>
					<Row>
						<Col size='3'>
							<Search
								id={`plan-search-${id}`}
								idButton={`button-plan-search-${id}`}
								label='Digite o nome do Plano'
								onSearch={onSearch}
								onKeyPress={onSearch}
								onChange={onChange}
							/>
						</Col>
						<Col size='8'>
							<Grid id='plan-list' flow='column' rows={parseInt(plans.length / COLUMNS + 1)} columns={COLUMNS}>
								{plans.map((item, index) => (
									<AvaliableItem key={`list-item-plan-${item}-${index}`}>{item}</AvaliableItem>
								))}
							</Grid>
							{/* <EmptyResult condition={plans.length < 1} /> */}
						</Col>
					</Row>
					<Row>
						<LabelButton id={`more-informations-${name}`} onClick={handleOpenSecondCollapse}>
							Mais Informações {secondCollapseOpened ? <IoIosArrowUp /> : <IoIosArrowDown />}
						</LabelButton>
					</Row>
					{secondCollapseOpened && (
						<Fade ref={secondRef} show={active}>
							<Content>
								<div className='left'>
									<SubTitle>Mais Informações</SubTitle>
									<InformacoesHTML
										dangerouslySetInnerHTML={{
											__html: informacoes || cosmicjsInformacoesEstaticas.metadata.convenio_geral
										}}
									/>
								</div>
								<div className='right'>
									<SubTitle>{cosmicjsInformacoesEstaticas.metadata.titulo_contato}</SubTitle>

									<InformacoesHTML
										dangerouslySetInnerHTML={{
											__html: cosmicjsInformacoesEstaticas.metadata.contato
										}}
									/>

									{cosmicjsInformacoesEstaticas.metadata.telefones_contato.map(item => (
										<>
											<strong>{item.titulo} </strong>
											<HighLight> {item.telefone}</HighLight>
											<br />
										</>
									))}
									<Divider />
								</div>
							</Content>
						</Fade>
					)}
				</CustomFade>
			</Collapse>
			{active && <Fade show={active} style={style} />}
		</div>
	)
}
