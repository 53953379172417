import React from 'react'
import styled from 'styled-components'

import ShowModal from './Modal'

import PurpleButtonStyled from '../Navbar/PurpleButton.styled'

import SVGClose from '@monorepo-amais/commons/Icons/Close'

const Logo = styled.div`
	margin: 0 auto;
	height: 61px;
`

const Submit = styled.button`
	background: ${props => props.theme.colors.primary};
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	margin: 40px 0;
	width: 100%;
	border: none;
	color: ${props => props.theme.colors.white};
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	&:focus,
	&:hover {
		background: ${props => props.theme.colors.primary_hover};
		outline: none;
	}
`

const TextCenter = styled.div`
	text-align: center !important;
`

const SelectBox = styled.div`
	max-width: 292px;
	margin: 40px auto 0;
`

const CTAButton = styled.h2`
	margin: 12px;
	.btn-schedule {
		${PurpleButtonStyled}
		background-color: #009FDE;
		//margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		font-weight: bold;
		height: 48px;
		width: 100%;
		padding: 1rem 2rem;

		&:not([disabled]):hover {
			background-color: #009fde;
			border-color: #009fde;
			opacity: 0.8;
		}

		&:not([disabled]):active {
			background-color: #009fde;
			border-color: #009fde;
		}

		margin-left: 2% !important;

		@media (max-width: 1290px) {
			width: 100%;
			margin-left: 1% !important;
		}

		@media (max-width: 1250px) {
			width: 100%;
			margin-left: 1% !important;
		}

		@media (max-width: 1100px) {
			width: 100%;
			margin-left: 1% !important;
		}

		@media (max-width: 1050px) {
			width: 100%;
			margin-left: 1% !important;
		}
	}
`

const CloseButton = styled.div`
	width: 32px;
	height: 32px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	margin: 12px;
`

const ModalConvenios = ({ text, textBtn, show, setShow, logo }) => (
	<ShowModal show={show}>
		<CloseButton onClick={() => setShow(false)}>
			<SVGClose />
		</CloseButton>
		<Logo>{logo}</Logo>
		<TextCenter className='infoText' dangerouslySetInnerHTML={{ __html: text }} />
		<SelectBox>
			<CTAButton
				id='button-scheduling-exams'
				onClick={() => window.open('https://agendamento.amaissaude.com.br', '_self')}
			>
				<button className='btn-schedule'>Agendar</button>
			</CTAButton>
		</SelectBox>
	</ShowModal>
)

export default ModalConvenios
