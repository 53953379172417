/**
 * Ordena os top convenios alfabeticamente e o restante alfabeticamente também.
 */
// export function sortConvenios(conveniosArray) {
//   const topConvenios = conveniosArray.filter
// }

/**
 * Ordena os top convenios na frente (usando o peso/) e o restante alfabeticamente.
 */
export const sortConvenios = (a, b) => {
	if (a['weight'] - b['weight'] !== 0) return b['weight'] - a['weight']
	else {
		return a['name'].toLowerCase() > b['name'].toLowerCase()
			? 1
			: a['name'].toLowerCase() < b['name'].toLowerCase()
			? -1
			: 0
	}
}

/**
 * Função para ser colocada no map e insere pesos nos convênios de acordo com a regional/marca
 * @returns o mesmo objeto com o peso 0 ou 1, sendo 1 mais prioritário
 */
export function insertWeightToConvenio(convenioObject) {
	const cosmicBucket = process.env.COSMIC_BUCKET
	let { name } = convenioObject
	name = name.toLowerCase()
	switch (cosmicBucket) {
		case 'amaissp':
			if (
				name.includes('amil') ||
				name.includes('bradesco') ||
				name.includes('central nacional unimed') ||
				name.includes('notre dame') ||
				name.includes('porto seguro') ||
				name.includes('sul américa')
			)
				return { ...convenioObject, weight: 1 }
			break
		case 'amaispr':
			if (
				name.includes('amil') ||
				name.includes('bradesco') ||
				name.includes('cassi') ||
				name.includes('ics') ||
				name.includes('nossa saúde') ||
				name.includes('unimed curitiba')
			)
				return { ...convenioObject, weight: 1 }
			break
		case 'amaispe':
			if (
				name.includes('amil') ||
				name.includes('bradesco') ||
				name.includes('fachesf') ||
				name.includes('geap') ||
				name.includes('sul américa') ||
				name.includes('unimed recife')
			)
				return { ...convenioObject, weight: 1 }
			break
		case 'amais-df':
			if (
				name.includes('bradesco') ||
				name.includes('central nacional unimed') ||
				name.includes('integra medical') ||
				name.includes('omint') ||
				name.includes('notre dame') ||
				name.includes('gama saúde')
			)
				return { ...convenioObject, weight: 1 }
			break
		case 'labs-amais':
			if (
				name.toLowerCase() === 'Amil'.toLowerCase() ||
				name.includes('assim saúde') ||
				name.includes('bradesco') ||
				name.includes('geap saúde') ||
				name.includes('petrobrás petróleo') ||
				name.includes('sul américa')
			)
				return { ...convenioObject, weight: 1 }
			break
		case 'irn':
			if (
				name.includes('amil') ||
				name.includes('asl') ||
				name.includes('cassi') ||
				name.includes('caurn') ||
				name.includes('petrobrás petróleo') ||
				name.includes('unimed natal')
			)
				return { ...convenioObject, weight: 1 }
			break
		case 'diagnoson':
			if (
				name.includes('amil') ||
				name.includes('bradesco') ||
				name.includes('central nacional unimed') ||
				name.includes('planserv') ||
				name.includes('sul américa')
			)
				return { ...convenioObject, weight: 1 }
			break
		// para as futuras marcas, fazer mais um case com o slug do bucket.
		default:
			return { ...convenioObject, weight: 0 }
	}
	// o padrão é ter o peso 0
	return { ...convenioObject, weight: 0 }
}
